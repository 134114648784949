import moment from 'moment'

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const monthsInFull = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
const year = '年'
const month = '月'
const day = '日'
const to = '至'

export function getDate(start, end) {
  let dateString = ''
  const startDate = new Date(start)
  dateString = months[(startDate.getMonth())] + ' ' + startDate.getDate() + ', ' + startDate.getFullYear() + ' (' + days[startDate.getDay()] + ')'
  if (end !== undefined) {
    const endDate = new Date(end)
    const date = months[(endDate.getMonth())] + ' ' + endDate.getDate() + ', ' + endDate.getFullYear() + ' (' + days[endDate.getDay()] + ')'
    date !== dateString ? dateString = dateString + ' - ' + date : null
  }
  return dateString
}

export function getTime(start, end) {
  let timeString = ''
  const startDate = new Date(start)
  const startHour = startDate.getHours()
  let startMinutes = startDate.getMinutes()
  startMinutes < 10 ? startMinutes = '0' + startMinutes : null
  if (startHour === 12) {
    timeString = '12:' + startMinutes + 'pm'
  } else {
    startHour > 12 ? timeString = (startHour - 12) + ':' + startMinutes + 'pm' : timeString = startHour + ':' + startMinutes + 'am'
  }
  if (end !== undefined) {
    const endDate = new Date(end)
    const endHour = endDate.getHours()
    let endMinutes = endDate.getMinutes()
    endMinutes < 10 ? endMinutes = '0' + endMinutes : null
    if (endHour === 12) {
      timeString += ' - 12:' + endMinutes + 'pm'
    } else {
      endHour > 12 ? timeString += ' - ' + (endHour - 12) + ':' + endMinutes + 'pm' : timeString += ' - ' + endHour + ':' + endMinutes + 'am'
    }
  }
  return timeString
}

export function apidaysEventDate(start, end, lang = 'en-US') {
  const startDate = new Date(start)
  if (!startDate) return null
  var startDateMonth = lang === 'en-US' ? monthsInFull[(startDate.getMonth())] : (startDate.getMonth() + 1)
  var startDateDate = startDate.getDate()
  var startDateYear = startDate.getFullYear()
  var startDateString = ''
  var endDateString = ''
  if (lang === 'en-US') {
    startDateString = startDateMonth + ' ' + startDateDate + ', ' + startDateYear
  } else {
    startDateString = startDateYear + year + startDateMonth + month + startDateDate + day
  }
  const endDate = end ? new Date(end) : null
  if (!endDate) return startDateString
  else {
    var endDateMonth = lang === 'en-US' ? monthsInFull[(endDate.getMonth())] : (endDate.getMonth() + 1)
    var endDateDate = endDate.getDate()
    var endDateYear = endDate.getFullYear()
    if (lang === 'en-US') {
      endDateString = endDateMonth + ' ' + endDateDate + ', ' + endDateYear
    } else {
      endDateString = endDateYear + year + endDateMonth + month + endDateDate + day
    }
    if (startDateString === endDateString) {
      return startDateString
    } else if (startDateYear !== endDateYear) {
      return lang === 'en-US' ? `${startDateString} - ${endDateString}` : `${startDateString}${to}${endDateString}`
    } else if (startDateMonth !== endDateMonth) {
      return lang === 'en-US'
        ? `${startDateMonth} ${startDateDate} - ${endDateMonth} ${endDateDate}, ${startDateYear}`
        : `${startDateYear}${year}${startDateMonth}${month}${startDateDate}${day}${to}${endDateMonth}${month}${endDateDate}${day}`
    } else if (Math.ceil(Math.abs(endDate - startDate)) / (1000 * 60 * 60 * 24) < 2) { // the date difference is 2 (consecutive days)
      return lang === 'en-US'
        ? `${startDateMonth} ${startDateDate} & ${endDateDate}, ${startDateYear}`
        : `${startDateYear}${year}${startDateMonth}${month}${startDateDate}${day}${to}$${endDateDate}${day}`
    } else return lang === 'en-US' ? `${startDateMonth} ${startDateDate} - ${endDateDate}, ${startDateYear}` : `${startDateYear}${year}${startDateMonth}${month}${startDateDate}${day}${to}$${endDateDate}${day}`
  }
}

export function getYYYYMMDD(date) {
  if (new Date(date)) {
    var formatDate = new Date(date)
    var month = formatDate.getMonth() + 1 < 10 ? `0${formatDate.getMonth() + 1}` : formatDate.getMonth() + 1
    var day = formatDate.getDate() < 10 ? `0${formatDate.getDate()}` : formatDate.getDate()
    return `${formatDate.getFullYear()}${month}${day}`
  } else {
    return null
  }
}

export function getDisplayDate(date, dateFormat = null, displayTime = false) {
  const defaultDate = 'YYYY-MM-DD'
  const defaultDateWithTime = 'YYYY-MM-DD HH:mm'
  const selectedFormat = dateFormat || (displayTime ? defaultDateWithTime : defaultDate)
  return date ? moment.utc(date).local().format(selectedFormat) : '-'
}
