export function formatName(value) {
  return value.replace(/[^A-Za-z0-9]/g, '-').replace(/\-+/g, '-')
}

export function getEventStatus(item) {
  if (item.endTime || item.startTime || item.date) {
    var date = item.date ? item.date.split('-') : null
    /* startTime comparison order
    1. item.startTime
    2. item.compareTime
    3. item.date */
    var startCompareTime = item.startTime ? item.startTime : (item.compareTime ? item.compareTime : (date ? date[0] : null))
    /* endTime comparison order
    1. item.endTime
    2. item.startTime
    3. item.compareTime
    4. item.date (check whether it is a single date or date range)
    */
    var endCompareTime = ''
    if (item.endTime) {
      endCompareTime = item.endTime
    } else if (item.endCompareTime) {
      endCompareTime = item.endCompareTime
    } else if (item.startTime) {
      endCompareTime = item.startTime
    } else if (item.date) {
      endCompareTime = date ? (date[1] ? date[1] : date[0]) : null
    }

    console.log(item.title, startCompareTime, endCompareTime)
    // return status
    if (!startCompareTime && !endCompareTime) return null
    if (new Date() >= new Date(endCompareTime)) {
      return { label: 'CC08-03c', value: 'ended' }
    } else if (new Date() >= new Date(startCompareTime)) {
      return { label: 'CC08-03b', value: 'in progress' }
    } else {
      return { label: 'CC08-03a', value: 'upcoming' }
    }
  } else {
    return null
  }
}

// From a category name, get its code in i18n.
const enJSON = require('@/locales/en-US/community-update.json')
export function getCategoryCode(key) {
  return Object.keys(enJSON).find(k => k.includes('option-category') && k.includes(key.toUpperCase())) || 'option-category-OTHER'
}

export function getCategoryKeyCodeByValue(value) {
  const result = Object.keys(enJSON).find(k => k.includes('option-category') && enJSON[k].toLowerCase().replace(/[^A-Za-z0-9]/g, '-').replace(/\-+/g, '-') === value.toLowerCase().replace(/[^A-Za-z0-9]/g, '-').replace(/\-+/g, '-')) || 'option-category-OTHER'
  return result.replace(/option-category-/g, '')
}

export function getCategoryFormattedName(key) {
  const category_key = Object.keys(enJSON).find(k => k.includes('option-category') && k.includes(key.toUpperCase())) || 'option-category-OTHER'
  return enJSON[category_key].toLowerCase().replace(/[^A-Za-z0-9]/g, '-').replace(/\-+/g, '-')
}

export function getIndustryCode(key) {
  return Object.keys(enJSON).find(k => k.includes('option-industry') && k.includes(key.toUpperCase())) || 'option-industry-OTHE'
}

export function getIndustryKeyCodeByValue(value) {
  const result = Object.keys(enJSON).find(k => k.includes('option-industry') && enJSON[k].toLowerCase().replace(/[^A-Za-z0-9]/g, '-').replace(/\-+/g, '-') === value.toLowerCase().replace(/[^A-Za-z0-9]/g, '-').replace(/\-+/g, '-')) || 'option-industry-OTHE'
  return result.replace(/option-industry-/g, '')
}

export function getIndustryName(key) {
  const industry_key = Object.keys(enJSON).find(k => k.includes('option-industry') && k.includes(key.toUpperCase())) || 'option-industry-OTHE'
  return enJSON[industry_key]
}

export function getIndustryFormattedName(key) {
  const industry_key = Object.keys(enJSON).find(k => k.includes('option-industry') && k.includes(key.toUpperCase())) || 'option-industry-OTHE'
  return enJSON[industry_key].toLowerCase().replace(/[^A-Za-z0-9]/g, '-').replace(/\-+/g, '-')
}
